import { AllEvents, DataLayerEventName } from '@news-mono/web-common'
import React from 'react'
import {
    StyledFlagContent,
    StyledIcon,
    StyledIconContainer,
    StyledLogo,
    StyledSTMEmail,
    StyledStmSocialContainer,
    StyledStmSocialFlag,
    StyledTitle,
} from '../../banners/SocialBanner/SocialBanner.styled'
import { applyContainerRatio } from '../../content/Picture/responsive'
import { IconDigitalEdition } from '../../icons/IconDigitalEdition/IconDigitalEdition'
import { IconEmail } from '../../icons/IconEmail/IconEmail'
import { IconFacebook } from '../../icons/IconFacebook/IconFacebook'
import { IconInstagram } from '../../icons/IconInstagram/IconInstagram'
import { IconTwitter } from '../../icons/IconTwitter/IconTwitter'
import { IconYoutube } from '../../icons/IconYoutube/IconYoutube'
import { PerthNowLogoVertical } from '../../logos/PerthNowLogo/PerthNowLogo'
import { metrics } from '../../__styling'

export interface SocialBannerRatios {
    mobile: 1
    tablet: 1 | 0.5
    desktop: 0.75 | 0.25
}

export interface SocialBannerProps {
    facebookUrl: string
    instagramUrl: string
    twitterUrl: string
    youtubeUrl?: string
    digitalEditionUrl?: string
    emailTo: string
    emailSubject: string
    ratios?: SocialBannerRatios
    verticalSpacing?: 'sm' | 'md'
    size: 'small' | 'large'
    onEvent?: (event: AllEvents) => void
}

export const SocialBanner: React.FC<SocialBannerProps> = ({
    twitterUrl,
    facebookUrl,
    instagramUrl,
    youtubeUrl,
    emailTo,
    emailSubject,
    digitalEditionUrl,
    ratios,
    verticalSpacing,
    size,
    onEvent,
}) => {
    const containerRatios = applyContainerRatio(ratios, {
        mobile: '100vw',
        tablet: '100vw',
        desktop: `1020px`,
        fallbackWidth: metrics.perthnow.siteMetrics.mainContentWidth,
    })

    const onDigitalEditionClick = () => {
        onEvent &&
            onEvent({
                type: DataLayerEventName.linkClicked,
                originator: 'SocialBanner',
                payload: {
                    text: 'Perth Now Digital Edition',
                    linkType: 'Digital-Edition-Driver-Social-Banners',
                    opensInNewWindow: false,
                    url: 'https://www.perthnow.com.au/editions',
                },
            })
    }

    return (
        <StyledStmSocialContainer
            containerRatios={containerRatios}
            verticalSpacing={verticalSpacing}
        >
            <StyledLogo size={size}>
                <PerthNowLogoVertical width={65} height={48} />
            </StyledLogo>

            <StyledStmSocialFlag size={size}>
                <StyledFlagContent
                    containerRatios={containerRatios}
                    size={size}
                >
                    <StyledTitle containerRatios={containerRatios}>
                        Your Local News
                    </StyledTitle>

                    <StyledIconContainer>
                        <StyledIcon
                            to={twitterUrl}
                            openExternalInNewWindow={true}
                            containerRatios={containerRatios}
                        >
                            <IconTwitter />
                        </StyledIcon>
                        <StyledIcon
                            to={facebookUrl}
                            openExternalInNewWindow={true}
                            containerRatios={containerRatios}
                        >
                            <IconFacebook />
                        </StyledIcon>
                        <StyledIcon
                            to={instagramUrl}
                            openExternalInNewWindow={true}
                            containerRatios={containerRatios}
                        >
                            <IconInstagram />
                        </StyledIcon>
                        {youtubeUrl && (
                            <StyledIcon
                                to={youtubeUrl}
                                openExternalInNewWindow={true}
                                containerRatios={containerRatios}
                            >
                                <IconYoutube />
                            </StyledIcon>
                        )}

                        <StyledSTMEmail
                            to={`mailto:${emailTo}?subject=${emailSubject}`}
                            containerRatios={containerRatios}
                        >
                            <IconEmail />
                        </StyledSTMEmail>
                        {digitalEditionUrl && (
                            <StyledIcon
                                to={digitalEditionUrl}
                                openExternalInNewWindow={false}
                                containerRatios={containerRatios}
                                onClick={onDigitalEditionClick}
                                forceInternalSSR
                            >
                                <IconDigitalEdition />
                            </StyledIcon>
                        )}
                    </StyledIconContainer>
                </StyledFlagContent>
            </StyledStmSocialFlag>
        </StyledStmSocialContainer>
    )
}
SocialBanner.displayName = 'SocialBanner'

export default SocialBanner
