import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

// side social banner has 'small' size
interface BannerSizeProps {
    size?: 'small' | 'large'
}

const backgroundImage: string = require('./components/PN-social-media-banner@2x.jpg')

interface StyledStmSocialFlagProps extends BannerSizeProps {}

export const StyledStmSocialFlag = styled('div')<StyledStmSocialFlagProps>(
    ({ size }) => {
        const isSideBanner = size === 'small'

        return {
            background: colors.perthnow.redRuby,
            color: colors.white,
            height: '100%',
            marginLeft: 'auto',
            position: 'relative',
            borderTopLeftRadius: calcRem(30),
            display: 'flex',
            alignItems: 'center',

            '&::before': {
                background: colors.perthnow.redRuby,
                content: `''`,
                height: '100%',
                left: calcRem(-12),
                position: 'absolute',
                top: 0,
                transform: 'skew(-34deg, 0deg)',
                width: calcRem(47),
            },

            [breakpoint('xxs')]: {
                borderTopLeftRadius: isSideBanner ? calcRem(30) : undefined,
                left: 0,

                '&::before': {
                    left: calcRem(-16),
                },
            },
        }
    },
)

interface SocialBannerContainerProps extends BannerSizeProps {
    containerRatios: {
        desktop: string
        mobile: string
        tablet: string
        fallbackWidth: number
    }
    verticalSpacing?: 'sm' | 'md'
}

const smallRatioLimit = 257

export const StyledStmSocialContainer = styled(
    'div',
)<SocialBannerContainerProps>(({ containerRatios, verticalSpacing }) => ({
    backgroundImage: `url('${backgroundImage}')`,
    backgroundPosition: 'center left',
    backgroundSize: 'cover',
    display: 'flex',
    fontFamily: fonts.perthnow.heading,
    height: 70,
    overflow: 'hidden',
    position: 'relative',
    textTransform: 'uppercase',
    marginBottom:
        containerRatios.fallbackWidth < smallRatioLimit
            ? calcRem(
                  metrics['perthnow'].margins[
                      verticalSpacing ? verticalSpacing : 'unset'
                  ],
              )
            : 0,
}))

export const StyledFlagContent = styled('div')<SocialBannerContainerProps>(
    ({ containerRatios, size }) => ({
        padding: calcRem(12, 12, 12, 0),
        position: 'relative',
        zIndex: 1,
        width: 'auto',

        [breakpoint('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: calcRem(9, 29, 9, 15),
        },

        [breakpoint('md')]: {
            padding:
                size === 'large'
                    ? calcRem(12, 23, 12, 12)
                    : calcRem(12, 12, 12, 0),
            flexDirection:
                containerRatios.fallbackWidth < smallRatioLimit
                    ? 'column'
                    : 'row',
            alignItems:
                containerRatios.fallbackWidth < smallRatioLimit
                    ? 'flex-end'
                    : 'center',
        },
    }),
)
export const StyledTitle = styled('h3')<SocialBannerContainerProps>(
    ({ containerRatios }) => ({
        fontSize: calcRem(22),
        lineHeight: calcRem(22),
        marginBottom: calcRem(4),
        textAlign: 'right',
        marginTop: 0,
        [breakpoint('sm')]: {
            margin: 0,
            marginRight:
                containerRatios.fallbackWidth < smallRatioLimit
                    ? 'none'
                    : calcRem(29),
            marginBottom:
                containerRatios.fallbackWidth < smallRatioLimit
                    ? calcRem(5)
                    : 0,
        },
    }),
)
export const StyledIcon = styled(WebLink)<SocialBannerContainerProps>(
    ({ containerRatios }) => ({
        alignItems: 'center',
        display: 'flex',
        fontSize: calcRem(18),
        fill: colors.white,
        height: calcRem(22),
        marginRight:
            containerRatios.fallbackWidth < smallRatioLimit
                ? calcRem(8)
                : calcRem(15),
        width: calcRem(22),

        '&:last-of-type': {
            marginRight: 0,
        },

        '&:hover': {
            fill: colors.black,
        },

        '& svg': {
            flex: 1,
            height: calcRem(22),
            width: calcRem(22),
        },
    }),
)

export const StyledIconContainer = styled('div')({
    display: 'flex',
    flex: 1,
})

export const StyledSTMEmail = styled(StyledIcon)({
    paddingTop: 2,
})

interface StyledLogoProps extends BannerSizeProps {}

export const StyledLogo = styled('div')<StyledLogoProps>(({ size }) => ({
    margin: calcRem(12, 0, 0, 12),

    [breakpoint('xxs')]: {
        margin:
            size === 'large' ? calcRem(12, 0, 0, 20) : calcRem(12, 0, 0, 12),
    },
}))
